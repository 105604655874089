<template>
  <div
    v-if="attributes?.slug"
    :class="$style.root"
  >
    <i18nLink
      :to="{ name: 'exhibitions-slug', params: { slug: attributes?.slug } }"
      :locale="i18nCode"
      :class="$style.link"
    >
      <LayoutImageWithCaptionComponent>
        <template #image>
          <CoreImageComponent
            v-if="getAttributes(attributes?.coverImage)"
            :image="getAttributes(attributes?.coverImage)"
            :class="$style.coverImage" :showCaption="false"
          />
        </template>

        <template #caption>
          <div v-if="attributes?.title">
            <span :class="$style.type" v-if="includeType">{{ $t('pages.exhibitions.label')?.toLowerCase() }}</span>{{ includeType ? '&nbsp;' : null }}<span :class="$style.externalLocationLabel" v-if="attributes?.externalLocation">{{ $t('shared.externalLocation') }}</span>{{ attributes?.externalLocation  ? '&nbsp;' : null}}<span :class="$style.title">{{ attributes?.title }}</span>
          </div>
          <CoreDateTimeRangeComponent
            v-if="attributes?.dateStart || attributes?.dateEnd"
            :class="$style.dateRange"
            :dateStart="attributes?.dateStart"
            :dateEnd="attributes?.dateEnd"
            format="D MMMM YYYY"
          />&nbsp;<CoreTimeLabelComponent
            :dateStart="attributes?.dateStart"
            :dateEnd="attributes?.dateEnd"
          />
        </template>

      </LayoutImageWithCaptionComponent>
    </i18nLink>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  includeType: {
    type: Boolean,
    default: false
  }
})

const getAttributes = baseStrapiGetAttributes

const localizedData = baseStrapiGetLocalizedData(props.data)

const attributes = baseStrapiGetAttributes(localizedData)
const i18nCode = baseI18nGetCodeForIso(attributes?.locale)
</script>

<style module>
.root {
  composes: font-helvetica font-size-small from global;
  position: relative;
}

.link {
  text-decoration-line: none;
}

.title {
  z-index: 2;
  position: relative;
  text-decoration-line: underline;
}

.root:hover .title {
  text-decoration-line: none;
}

.type {
  composes: font-size-small font-helvetica label-inverted from global;
}

.coverImage {
  --base--image--max-aspect-ratio: 1.25;
}

.externalLocationLabel {
  composes: font-size-small font-helvetica label-inverted from global;
}
</style>
